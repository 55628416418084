import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://b13d367742e0ace8b313ecb168bac87e@o4507849618685952.ingest.us.sentry.io/4507849680355328",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});